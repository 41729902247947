.fixedUtils {
  position: fixed;
  right: 5px;
  bottom: 10px;
}
.fixedUtils .btn {
  display: none;
  flex-direction: column;
}
.fixedUtils .btn > button {
  margin: 3px;
}
.fixedUtils:hover > .btn {
  display: flex;
}
.fixedUtils .default-show {
  text-align: right;
}

.demo-loadmore-list {
  min-height: 350px;
  background-color: #fff;
}
.demo-loadmore-list .ant-list-item-meta {
  align-items: center;
}
.ant-list-item-action li {
  cursor: pointer;
}
.ant-list-item-meta-title .title-time {
  margin-left: 15px;
  font-size: 13px;
}
.ant-list-item-meta-title span[hv]:hover {
  color: tomato;
}
.ant-list-item-meta-description {
  cursor: pointer;
}
.ant-list-item-meta-description:hover {
  color: yellowgreen;
}

@import '~antd/dist/antd.css';
body {
  background-color: #f0f2f5;
}
.App {
  min-width: 1366px;
}
.App .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.2);
}
.header-img img {
  transition: transform 1s;
}
.header-img img:hover {
  transform: rotate(360deg);
}
.scroll-box::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.scroll-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.scroll-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

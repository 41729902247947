.base-details {
  background-color: #fff;
  padding: 20px 10px;
  display: flex;
  justify-content: space-around;
}
.base-details .details-left {
  width: 60%;
}
.base-details .details-left span[hv="tomoto"] {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 700;
}
.base-details .details-left span[hv="tomoto"]:hover {
  color: tomato;
}
.base-details .details-left .user-list .ant-image {
  height: 100%;
}
.base-details .details-left .user-list .ant-image img {
  height: 100%;
}
.base-details .details-right {
  width: 35%;
  background-color: #f0f2f5;
  padding: 5px;
}

.my-carousel {
  background-color: #fff;
  margin-bottom: 10px;
}
.my-carousel .swiper {
  width: 100%;
  height: 100%;
}
.my-carousel .swiper-wrapper {
  align-items: center;
}
.my-carousel .swiper-slide {
  max-height: 380px;
  overflow: hidden;
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.my-carousel .swiper-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.comment-list > .reply-title {
  text-align: center;
  line-height: 46px;
  border-bottom: solid 1px black;
}
.reply-title > span:nth-child(1) {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 700;
  color: black;
}
.reply-title > span:nth-child(1):hover {
  color: tomato;
}
.reply-pre {
  box-sizing: border-box;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-weight: 566;
  border-bottom: 1px solid #f0f0f0;
  margin: 0;
  padding-top: 2px;
  padding-bottom: 2px;
}
.reply-pre img {
  max-height: 80px;
  border-radius: 10px;
}

.user-details-edit .user-details-repas {
  max-width: 390px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.user-details-edit .user-details-repas > span {
  margin-top: 10px;
}
.user-details-edit .user-details-update {
  max-width: 390px;
  margin: 0 auto;
}

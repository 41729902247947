.home-view {
  display: flex;
  justify-content: space-around;
}
.home-view .home-left {
  width: 60%;
}
.home-view .home-right {
  width: 35%;
}

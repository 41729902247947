.base-view {
  display: flex;
  justify-content: space-around;
}
.base-view .view-left {
  width: 60%;
}
.base-view .view-right {
  width: 35%;
}

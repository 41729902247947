.reply-comment {
  margin-top: 20px;
  background-color: #f0f2f5;
  padding-left: 10px;
  padding-right: 10px;
}
.reply-comment .reply-gif {
  position: absolute;
  top: 0;
  left: 0;
  border: gold solid 1px;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2;
}
.reply-comment .reply-gif .img-gifs {
  max-height: 80px;
  float: left;
  transition: transform 0.6s;
}
.reply-comment .reply-gif .img-gifs:hover {
  transform: scale(1.1);
}
